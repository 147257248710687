.spot-container{
    color: var(--white-color);
}


@media (min-width: 1920px) {
    /* .fluid-header {
      padding: 10px 15px;
    }
    .header-fixed.sticky.fluid-header { 
      padding: 10px 15px;
    } */
  
  
    .trade_body .fluid-header {
      padding: 4px 0;
      }
  
  
  
  }
  @media (max-width: 1919px) {
    /* .fluid-header {
      padding: 10px 15px;
    }
    .header-fixed.sticky.fluid-header { 
      padding: 10px 15px;
    } */
  }
  @media (max-width: 1499px) {
    /* .fluid-header {
      padding: 10px 15px;
    }
    .header-fixed.sticky.fluid-header { 
      padding: 10px 15px;
    } */
    .trade_body .fluid-header {
          padding: 5px 15px;
      }
  
  
  }
   
  
  
  
  .trade-wrapper {
    padding-top: 100px !important;
}
  
   
  .spot-container-main {
      display: flex;
      flex-direction: column;
      /*padding: 0 15px;*/
      flex: 1;
  }
  .spot-container-main .top {
      display: flex;
  }
  .trade_row{
      margin: 0 0;
  }
  .trade_col {
      padding: 0 5px;
      width: 100%;
  }
  
  
  .spot .title{
      /* color: #ffffff; */
  }
  
  
  .spot-container-main .top-left {
      flex-grow: 1;
  }
  
  .spot-container-main .top-right {
      width: 380px;
  }
  
  
  
  
  .container-chart-briefing {
      height: 80px;
      position: relative;
      justify-content: flex-start;
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
  }
  
  .trade_card {
    background: #131722;
    border: 1px solid rgb(255 255 255 / 8%);
    border-radius: 10px;
    overflow: hidden;
}
 
  
.theme-light .trade_card {
    background: #fff;
    border: 1px solid rgb(255 255 255);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}
  
  .container-chart-briefing-info {
      font-size: 12px;
      text-align: left;
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items:center;
  }
  
  dd, dl, dt {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    line-height: 1.5;
}
  
  .container-chart-briefing h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      padding-right: 20px;
      cursor: pointer;
      font-weight: 700;
      color: #ffffff;
  }
  
  .container-chart-briefing-info dd.big, .container-chart-briefing-info dt.big {
      font-size: 18px;
      font-weight: 700;
  }
  
  .container-chart-briefing-info dd.green, .container-chart-briefing-info dt.green {
      color: var(--bs-green)!important;
  }
  
  .container-chart-briefing .displayName>span {
      padding-right: 10px;
  }
  
 
.trade-wrapper .btn { 
    justify-content: center;
}
  
dd {
    font-weight: 600;
}
  


  
  .tv-chart {
      width: 100%;
      height: 507px;
  }
  
  
  /*order book*/
  
  .card_title {
      font-size: 16px;
      font-weight: 600;
      /*border-bottom: 1px solid rgb(64 64 64);*/
      text-align: center;
      padding-bottom: 1rem;
      color: currentColor;
  }
  
  .theme-light .card_title {
      /*border-color: rgb(0 0 0 / 5%);*/
  }
  
  .trade_tabs {
      padding: 10px; 
  }
  
  .trade_card_body.single-widget ul li a {
      font-size: 14PX;
  }
  
  .price_card_head {
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 12px;
      justify-content: space-between;
      opacity: 0.7;
      margin-bottom: 10px ;
  }
  
  .price_card_body::-webkit-scrollbar,
  .scroll_y::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
  }
  .price_card_body::-webkit-scrollbar-thumb,
  .scroll_y::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgb(106 106 106 / 21%);
  }
  .theme-light .price_card_body {
      /*border: 1px solid rgb(0 0 0 / 5%);*/
  }
  .price_item_value {
      padding-bottom: 10px;
      border-bottom: 1px solid #ffffff14;
      display: flex;
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 7px 0;
  }
  .price_item_value:hover {
    background: #ffffff14;
}

  .theme-light .price_item_value {
      border-bottom: 1px solid rgb(0 0 0 / 5%);
  }
  .price_item_value span {
      display: block;
      /*color: #ffffff;*/
      font-size: 11px;
  }
  .price_item_value span:first-child,
  .price_card_head > div:first-child {
      display: block;
      width: 150px;
      padding-left: 10px;
  }
  .price_item_value span:nth-child(2),
  .price_card_head > div:nth-child(2) {
      display: block;
      width: 150px;
      text-align: left;
  }
  .price_item_value span:last-child, .price_card_head > div:last-child {
      display: block;
      width: 95px;
      text-align: right;
      padding-right: 10px;
      font-weight: 700;
  }
  
  
  .price_item_value:last-child{
      border: 0;
  }
  .live_ppc {
      font-weight: 500;
      padding: 1rem 0;
      line-height: 1;
  }
  .price_card_body.price_card_body_full, .scroll_y {
      height: 366px;
      overflow-y: auto;
      overflow-x: hidden;
  }
  .buy_sell_row_price .scroll_y {
        max-height: 344px;
    }
  .spot-container-right-right {
      flex: 0 0 300px;
      max-width: 300px;
  }
  .order-4 { 
      margin: 0;
  }
  
  .buy_sell_cards ul.nav.custom-tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      box-shadow: none !important;
      background: transparent !important; 
      width: 100%;
  }
  
  .buy_sell_cards ul.nav.custom-tabs.nav_order li {
      /* max-width: fit-content; */
      flex: 1 1 auto;
      text-align: center;
  }
  
  
  .buy_sell_cards ul.nav.custom-tabs li a {
    /* box-shadow: rgb(106 106 106 / 21%) 0px 0px 0px 1px; */
    display: block;
    text-align: center;
    border-radius: 40px;
    padding: 4px 6px;
    color: #fff;
    /* background-color: #ffffff0d; */
    font-size: 12px;
}
  
.buy_sell_cards ul.nav.custom-tabs li a.active {
    background-color: var(--custom-btn-bg-color) !important;
    color: var(--white-color) !important;
    opacity: 1 !important;
}
  
  
  .theme-light .buy_sell_cards ul.nav.custom-tabs li a {
  
      color: rgba(0, 0, 0, 0.75);
  }
  .custom-tabs li.buy-tab a:hover {
      color: rgba(255, 255, 255, 0.75);
      /*background-color: var(--bs-green)54;*/
  }
  
  .custom-tabs li.sell-tab a:hover {
      color: rgba(255, 255, 255, 0.75);
      /*background-color: #f93f312b;*/
  }
  
  
  .custom-tabs li.buy-tab a.active,
  .custom-tabs li.buy-tab a:active {
      color: #fff !important;
      background-color: var(--bs-green) !important;
  }
  
  .custom-tabs li.sell-tab a.active,
  .custom-tabs li.sell-tab a:active {
      color: #fff !important;
      background-color: #f93f31 !important;
  }
  .custom-tabs li.buysell-tab a.active {
      color: #ffffff !important;
      background-color: var(--bs-green) !important;
  }
  
  
  .spot-deal-warp {
      margin-top: 25px;
  }
  
  
  
  
  
  
  .spot  .input-group {
      background: transparent;
      border-radius: 40px;
      overflow: hidden;
      box-shadow: rgb(106 106 106 / 21%) 0px 0px 0px 1px;
  }
  .spot .input-group > span {
      text-align: center !important;
  }
  
  .spot .input-group > span small {
      text-align: center;
      margin: 0 auto;
  }
  .input-group-text {
      /*background-color: #20202d0d;*/
      /* border: 1px solid #20202d; */
      border-radius: 0;
      color: currentColor;
      line-height: 1.2;
      min-width: 76px;
      font-weight: 500;
      border: 0;
       background: transparent; 
  }
  .input-group-text  small {
      font-size: 75%;
  }
  .spot .form-control, .spot select {
      color: currentColor;
      background-color: #2f2f39;
      border: 0; 
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      border-radius: 3px !important;
      background: #ffffff17;
      color: #ffffff;
      backdrop-filter: blur(10px);
      border: 1px solid #ffffff1c;
      padding: 7px 18px;
      min-width: 150px;
      border-radius: 6px !important;
  }
  .theme-light .spot .form-control {
      background-color: transparent;
  }
  .form-control:focus {
      box-shadow: none;
  }
  
  .btn-group.btn-group-mini {
      width: 100%;
      border-radius: 0 !important; 
      border: 0; 
      background: transparent;
      margin-left: auto !important;
      margin-right: 4px;
  }
  .btn-group.btn-group-mini * {
      line-height: 35px;
      font-size: 12px;
      background: transparent;
      border: 0;
      transition: 0.0s;
      padding: 0 0px;
      color: #ffffff;
      margin: 0 auto !important;
      width: -webkit-fill-available;
      text-align: center;
      display: grid;
      min-height: auto;
      box-shadow: none !important;
      height: 35px;
      margin: 0 2px!important;
      border-radius: 40px !important;
      background: rgba(255, 255, 255, 0.12);
      border: 1px solid rgb(255 255 255 / 9%);
      backdrop-filter: blur(10px);
      min-width:35px;
  }
  
  .theme-light .btn-group.btn-group-mini * {
    background: rgb(171 171 171 / 12%);
    border: 1px solid rgb(234 239 244);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: #000;
}
  
  
  .btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
      box-shadow: none !important;
  }
  .btn-group.btn-group-mini *:focus{
      background: transparent;
  }
  .data-buy .btn-group.btn-group-mini .btn-outline-success {
      
  }
  .data-sell .btn-group.btn-group-mini .btn-outline-danger {
      
  }
  .btn-dark {
      background: #ffffff26;
  }
  .btn-check+.btn:hover {
      color: #ffffff; 
  }
  
  .btn-check+.btn:hover {
      color: var(--bs-btn-color);
      background-color: var(--bs-btn-bg);
      border-color: non;
  }
   
  .data-buy .btn-group.btn-group-mini .btn-check:checked + .btn-outline-success,
  .data-buy .btn-group.btn-group-mini .btn-outline-success:hover {
      background: var(--bs-green);
      color:#fff;
  }
  .data-sell .btn-group.btn-group-mini .btn-check:checked + .btn-outline-danger,
  .data-sell .btn-group.btn-group-mini .btn-outline-danger:hover {
      background: #f93f31;
      color: #fff;
  }
  .data-sell .btn-group.btn-group-mini .btn-check:checked + .btn-outline-success{ 
      border-color: var(--bs-green) !important;
  }
  .data-sell .btn-group.btn-group-mini .btn-check:checked + .btn-outline-danger{ 
      border-color: var(--bs-red) !important;
  }
  
  
  .trade-wrapper select, .trade-wrapper .nice-select { 
      box-shadow: rgb(106 106 106 / 21%) 0px 0px 0px 1px;
      height: 44px;
      background: #ffffff17;
      color: #ffffff;
      width: 100%;
      backdrop-filter: blur(10px);
      border-radius: 50px;
      padding: 0 16px;
  }
  .trade-wrapper select *, .trade-wrapper .nice-select * {
      /* font-size: 13px; */
     color: #ffffff !important;
  background:#000;
  }
  .btn-block {
      display: block;
  }
  
  small.note {
      font-size: 74%;
      display: block;
      text-align: center;
      margin: 18px 12px 0;
      line-height: 1.2;
  }
  
  .buy_sell_cards button {
      margin: 1.5rem 0 1rem;
  }
  
  
  
  .trade_assets {
      margin-top: 1rem;
  }
  .trade_assets .product-owner {
      padding: 0.5rem 0;
  
      border-bottom: 1px solid #2f2f39;
  }
  .trade_assets .product-owner:last-child{
      border-bottom: 0;
  }
  .theme-light .trade_assets .product-owner {
      border-color: rgb(0 0 0 / 5%);
  }
  
  
  .trade_card table.table, .table tbody tr:hover     {
    background-color: transparent;
}


.form-select-sm {
    padding: 5px 6px !important;
    height: unset !important;
    width: min-content !important;
    min-width: unset !important;
}
 
.trade_card .table > :not(caption) > * > * {
    padding: 1rem .5rem;
    line-height: 1.5 !important;
    vertical-align: middle;
}
.trade_card .table > .btn {
    margin: 0;
}


  .spot-container-main .bottom {
      display: flex;
      min-height: 500px;
      /*border-top: 1px solid #191e23;*/
      flex: 1;
  }
  
  
  .bottom-left{
      width: calc(100% - 380px);
  }
  
  .bottom-right{
      width: 380px;
  }
  ul.nav.custom-tabs.order_his_tabs li {
      max-width: -webkit-fill-available;
      width: auto;
      padding: 0;
  }
  ul.nav.custom-tabs.order_his_tabs {
      justify-content: flex-start;
  }
  ul.nav.custom-tabs.order_his_tabs li a {
    padding: 4px 16px;
    height: unset !important;
}
 .ant-list-item{
    display: flex;
    height: 42px;
    padding: 5px 16px;
    cursor: pointer;
 } 
 .rankItem {
    height: 27px;
    line-height: 12px;
    width: 144px;
}
  
  
  
  
  
  .container-chart-briefing .spotList {
      width: 360px;
      min-height: 360px;
      padding-bottom: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #ffffff12;
      position: absolute;
      top: 98px;
      left: 6px;
      z-index: 111111;
      border-radius: 9px;
      display: none;
      box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
      padding: 0;
      backdrop-filter: blur(10px);
  }
  .show_spot{
      display: block !important;
  }
  
  .spotList .scroll_y {
      height: 357px;
  }
  
  .spot-list {
      position: absolute;
      left: 0;
      top: 0;
      width: 360px;
      min-height: 100%;
      background: #1f252a;
  }
  .spot-list-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 10px;
      padding-top: 0;
  }
  
  .spot-list-search .ivu-input {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 0 12px;
  } 

.theme-light .spot-list-search .ivu-input {
    background: rgb(207 207 207 / 5%);
    border: 1px solid rgb(211 211 211 / 57%);
}
  
  
  .spot-list-search i {
  
      margin-right: 14px; 
  }
  .spot-list-search .ivu-input input {
      background: transparent;
      border: 0;
      outline: 0 !important;
      color: #ffffff;
      padding: 6px 3px;
      font-size:14px;
      width: 100%;
  }
  
  
  .price_card {
      padding: 10px 0;
      padding:0;
  }
  
  .price_card .price_card_head {
      padding: 0 10px;
      padding-right: 0;
  }
  .price_card_body {
      padding: 0 10px; 
  }
  .price_card_body.scroll_y { 
      padding-right: 0;
      padding-left: 0;
  }
  .price_item_value .ri-xl {
      font-size: 116%;
  }
  
  .spot-list-search .ivu-input::placeholder{
      color: #ffffff;
  }
  
  
  
  
  .theme-light .container-chart-briefing .spotList {
      background: #ffffff;
      box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  }
  
  
  .trade-wrapper .favouriteData {
      text-align: center;
      padding: 8% 0;
      font-size: 300%;
  }
  
  .trade-wrapper .favouriteData p {
      color: #ffffff;
  }
  
  
  .scroll_y .favouriteData {
      height: 100%;
  }
  
  .mrkt_trde_tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      padding: 10px;
      border-top: 1px solid #ffffff14;
      border-bottom: 1px solid #ffffff14;
      margin: 10px 0;
  }
  .trade-wrapper .text-success {
      color: var(--bs-green) !important;
  }
  .theme-light  .mrkt_trde_tab { 
      border-top: 1px solid #00000014;
      border-bottom: 1px solid #00000014; 
  }
  
  
  
  
  .buysellform .input-group {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.12);
    border: 1px solid rgb(255 255 255 / 9%);
}
  
.buysellform .input-group .form-control {
    background: transparent;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0 !important;
    font-size: 15px !important;
    backdrop-filter:blur(0);
}

.buysellform .input-group .input-group-text, .buysellform .input-group .input-group-text * {
    padding: 0;
    margin: 0;
    text-align: right !important;
    display: block;
}
  
  
.buy_sell_row.buy_sell_cards ul.nav.custom-tabs li a {
    padding: 0;
    border-radius: 3px;
    margin: 0 7px 0 0;
    background: transparent !important;
    line-height: 1;
    opacity: 0.5;
}

  
.theme-light  .buy_sell_row.buy_sell_cards ul.nav.custom-tabs li a {
    background: #0000001f;
}

.buy_sell_row.buy_sell_cards ul.nav.custom-tabs li {
    max-width: fit-content;
}
.buy_sell_row.buy_sell_cards ul.nav.custom-tabs {   
    justify-content:end;
}
    
  
  
  .buy_sell_row  h6 {
      font-size: 12px;
  }
  
  .buy_sell_row {
    border-bottom: 1px solid #ffffff1a;
    margin-bottom: 10px;
    padding: 10px 10px;
}
  .theme-light .buy_sell_row {
      border-bottom: 1px solid #d2e4ff; 
  }
  
   .actv_data  p,
    .actv_data span {
      font-size: 13px;
  }
   
  
    .trade-wrapper .row {
        margin-left: -5px;
        margin-right: -5px;
    }
    .trade-wrapper .row > * {
        padding-left: 5px;
        padding-right: 5px;
    }
  
 
    .num-select {
        padding: 0 !important;
        background: transparent !important;
        border: 0 !important;
        width: 32px  !important;
        outline: 0 !important;
        box-shadow: none !important;
        min-width: min-content !important;
        text-align: right;
        height: -moz-fit-content !important;
        height: fit-content !important;
        font-size: 13px;
        line-height: 1.1;
    }
    .num-select *{
        max-width: unset !important;
        color: currentColor; 
    }
    .theme-light .num-select *{  
        color: #fff; 
    }
    .num-div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 1px 0 1px;
        position: relative;
        background:  #ffffff2e;
        margin-left: 4px;
        border-radius: 4px;
    }
   
    .num-div:after {
        font-family: "remixicon" !important;
        font-style: normal;
        content: "\ea4d";
        color: #fff;
        font-size: 17px;
        /* position: absolute; */
        /* right: 0; */
        /* top: 0; */
        /* height: 15px; */
        line-height: 1;
    }

    .theme-light .num-div:after{
        color: #333;
    }
    .theme-light .num-div {
        background: #0000001f;
    }



.tt_pp_header{
    min-height: 69.8px;
}

    .trade_card.trade_chart {
        min-height: 343px;
    }
  
  @media screen and (max-width: 767px) and (min-width: 320px){
    
    
  
      .container-chart-briefing .spotList {
          background: #22242c;
          /* box-shadow: rgb(0 0 0 / 58%) 0px 0 0 500px; */
          left: 0;
          top: 62px;
      }
  
  
      .container-chart-briefing  {
        height: auto;
        padding: 15px 15px 0;
      }
    
    .spot-container-main .top {
        flex-direction: column;
    }
    .spot-container {
        flex-direction: column;
    }
    .wm-50 {
        flex: 0 0 auto;
        margin-bottom: 17px;
        width: 50%;
    }
    .container-chart-briefing-info {
        width: 100%;
        justify-content: unset;
        flex-wrap: wrap;
    }
    .container-chart-briefing h2 {
        justify-content: flex-start;
    }
    
    .spot-container-main .bottom {
        min-height: unset;
        flex-direction: column;
    }
    .bottom-left {
        width: 100%;
        margin-bottom: 20px;
    }
    .bottom-right {
        width: 100%;
    }
    .spot-container-right-right {
        flex: auto;
        max-width: 100%;
    }
    .trade_card {
        padding: 11px 11px;
    }
    ul.nav.custom-tabs.order_his_tabs li a {
        padding: 7px 20px;
        font-size: 14px;
    }
    
    
    
    
    
    }
      
    
    
    @media screen and (max-width: 991px) and (min-width: 768px){
    
    
    
      .spot-container-main .top {
          flex-direction: column;
      }
      .spot-container {
          flex-direction: column;
      }
      .wm-50 {
        flex: 0 0 auto;
        margin-bottom: 17px;
        width: 33.3333333%;
    }
      .container-chart-briefing-info {
          width: 100%;
          justify-content: unset;
          flex-wrap: wrap;
      }
      .container-chart-briefing h2 {
          justify-content: flex-start;
      }
      
      .spot-container-main .bottom {
          min-height: unset;
          flex-direction: column;
      }
      .bottom-left {
          width: 100%;
          margin-bottom: 20px;
      }
      .bottom-right, .spot-container-main .top-right {
          width: 100%;
      }
      .spot-container-right-right {
          flex: auto;
          max-width: 100%;
      }
    
      ul.nav.custom-tabs.order_his_tabs li a {
          padding: 7px 20px;
          font-size: 14px;
      }
      .container-chart-briefing {
        height: unset;
        padding-bottom: 10px;
    }
    .trade-wrapper.spot {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
    }
      
      
      
      }
    
    
    
    
    
    
      @media screen and (max-width: 1299px) and (min-width: 992px){
    
        .spot-container{
            flex-direction: column;
        }
        .spot-container-right-right {
            flex: auto;
            max-width: 100%;
            display: flex;
        }
    
        .inner-page-banner {
            padding: 77px 0 43px;
        }
        section.pb-90.account_sec {
            padding-bottom: 0;
        }
      }
  
  
  
  
  
  