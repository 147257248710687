/* .centerbox {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: rgb(0 0 0 / 52%);
  position: fixed;
  z-index: 999999;
  top: 0;
  backdrop-filter: blur(5px);
}
  
.centerbox img{
  max-width:80px; 
}




.load {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  float: left;
}

.progress {
  top: 50%;
  left: -80%;
  position: absolute;
  transform: translate(-50%, -50%);
  content: '';
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  animation: loading_492 1.5s ease infinite;
  animation-delay: .5s;
}

@keyframes loading_492 {
  0% {
    width: 0%;
    height: 0%;
  }

  50% {
    width: 99%;
    height: 99%;
  }

  100% {
    width: 0%;
    height: 0%;
  }
}

.progress:nth-child(2) {
  left: 50%;
  animation-delay: 1.3s;
}

.progress:nth-child(3) {
  left: 180%;
  animation-delay: 1.7s;
} */

.centerbox {
  align-items: center;
  align-self: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.52);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
}

.loader {
  width: 60px;
  height: 60px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: "";
  width: 60px;
  height: 5px;
  background: #eacb6c40;
  position: absolute;
  top: 80px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.8s linear infinite;
}

.loader:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #f08080;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.7s linear infinite;
  background: url(https://polarize.exchange/images/favicon/favicon.png);
  background-repeat: no-repeat;
  background-size: 60px;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px);
  }

  50% {
    transform: translateY(18px);
  }

  75% {
    transform: translateY(9px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}
